import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import React, { createContext, useContext } from 'react';

const NotifyContext = createContext<{ api?: NotificationInstance }>({
  api: undefined,
});

notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 0.2,
  rtl: true,
});

export const NotifyProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <NotifyContext.Provider value={{ api }}>
        {children}
      </NotifyContext.Provider>
    </>
  );
};

export const useNotify = () => useContext(NotifyContext);
