import {
  Input as InputAnt,
  InputNumber as InputNumberAntd,
  InputNumberProps,
  InputProps,
} from "antd";

export const Input = InputAnt;

export const InputNumberNative: React.FC<InputNumberProps> = ({ ...props }) => (
  <InputNumberAntd {...props} style={{ width: "100%" }} />
);

export const InputNumber: React.FC<
  Omit<InputProps, "onChange"> & { onChange?: (e?: number) => void }
> = ({ onChange, ...props }) => {
  const onChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.replace(/\D+/g, "");
    if (onChange) onChange(Number(val || 0));
  };

  return <InputAnt {...props} type="number" onChange={onChangeNumber} />;
};

export const InputCurrency: React.FC<InputNumberProps> = ({ ...props }) => {
  return (
    <InputNumberNative
      {...props}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
    />
  );
};
