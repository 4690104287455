import React from "react";

interface FlexProps extends React.CSSProperties {
  children?: React.ReactNode;
  className?: string;
}

export const Flex: React.FC<FlexProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        ...props,
      }}
    >
      {children}
    </div>
  );
};
