import React, { createContext, useContext, useMemo, useReducer } from "react";
import { IAdmin } from "services/admin/types";

type AuthPayload = {
  user?: IAdmin;
};

type AuthContextType = {
  state: AuthPayload;
  dispatch: React.Dispatch<AuthActionType>;
};

//* actions define
export enum AuthAction {
  setAuth = "set-auth",
  clearAuth = "clear-auth",
}

type SetAuth = {
  type: AuthAction.setAuth;
  payload: IAdmin;
};

type ClearAuth = {
  type: AuthAction.clearAuth;
  payload: undefined;
};

type AuthActionType = SetAuth | ClearAuth;

const initialState: AuthPayload = {
  user: undefined,
};

//* context define
const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
});

//* reducer define
const reducer = (state: AuthPayload, action: AuthActionType) => {
  switch (action.type) {
    case AuthAction.setAuth:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export const AuthProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
