import { UploadOutlined } from "@ant-design/icons";
import { IMAGE_TYPE_ACCEPT } from "constants/schema";
import { useAsync } from "hooks/useAsync";
import { useNotify } from "providers/notify";
import React, { useId } from "react";
import { apiUtilsUploadSingle } from "services/utils";
import { Flex } from "../flex";

interface UploadProps {
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  accept?: string;
  type?: "audio" | "image";
}

export const Upload: React.FC<UploadProps> = ({
  value,
  onChange,
  readOnly,
  accept = "image/png, image/jpeg",
  type = "image",
}) => {
  const { api } = useNotify();
  const [executeUpload] = useAsync(apiUtilsUploadSingle, {
    onSuccess: (res) => {
      if (onChange) {
        onChange(res.data.data);
      }
    },
    onFailed() {
      api!.error({
        message: `Thông báo`,
        description: `Upload file lỗi`,
      });
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;
    const file = e.target?.files[0];
    if (!IMAGE_TYPE_ACCEPT.includes(file.type)) return;
    executeUpload(file);
    e.target.value = "";
  };

  const id = useId();
  console.log("value", value);
  return (
    <>
      <label
        htmlFor={id}
        style={{
          cursor: "pointer",
          border: "1px dashed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          padding: 20,
          position: "relative",
          pointerEvents: readOnly ? "none" : undefined,
        }}
      >
        {!value && <UploadOutlined style={{ fontSize: 30 }} rev={undefined} />}
        {value && (
          <Flex
            display="block"
            maxWidth={700}
            width={"100%"}
            textAlign="center"
          >
            {type === "image" ? (
              <img style={{ maxWidth: "100%" }} alt="hinh anh" src={value} />
            ) : (
              <audio controls muted>
                <source src="horse.ogg" type="audio/ogg" />
                <source src={value} type="audio/mpeg" />
              </audio>
            )}
          </Flex>
        )}
        <input
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }}
          onChange={handleChange}
          id={id}
          type="file"
          accept={accept}
        />
      </label>
    </>
  );
};
