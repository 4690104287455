export const GROUP_CUSTOMER = [
  {
    value: "retail_customer",
    label: "Khách lẻ",
  },
  {
    value: "agency_1",
    label: "Đại lý cấp 1",
  },
  {
    value: "agency_2",
    label: "Đại lý cấp 2",
  },
  {
    value: "agency_3",
    label: "Đại lý cấp 3",
  },
];
