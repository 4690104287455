import http from "../http";

import { IAdmin, IParamsAdminCreate } from "./types";

export const apiAdminGetList = (params?: IParamsPagination) =>
  http.get<BaseAPIResponse<Array<IAdmin>>>("/api/admin", {
    params,
  });

export const apiAdminGetDetail = (id: string) =>
  http.get<BaseAPIResponse<IAdmin>>(`/api/admin/detail/${id}`);

export const apiAdminCreate = (body?: IParamsAdminCreate) =>
  http.post<BaseAPIResponse<IAdmin>>("/api/admin/create", body);

export const apiAdminUpdate = (id: string, body: IParamsAdminCreate) =>
  http.patch<BaseAPIResponse<IAdmin>>(`/api/admin/update/${id}`, body);

export const apiAdminDelete = (id: string) =>
  http.delete<BaseAPIResponse<unknown>>(`/api/admin/delete/${id}`);

export const apiAdminLogin = (body?: { username: string; password: string }) =>
  http.post<BaseAPIResponse<{ accessToken: string }>>("/api/admin/login", body);

export const apiAdminLogout = () =>
  http.post<BaseAPIResponse<unknown>>("/api/admin/logout");

export const apiAdminGetInfo = () =>
  http.get<BaseAPIResponse<IAdmin>>("/api/admin/me");
