import http from "../http";

import { IAdminUser, IParamsAminUserCreate } from "./types";

export const apiAdminUserGetList = (params?: IParamsPagination) =>
  http.get<BaseAPIResponse<Array<IAdminUser>>>("/api/user/admin/all", {
    params,
  });

export const apiAdminUserGetDetail = (id: string) =>
  http.get<BaseAPIResponse<IAdminUser>>(`/api/user/admin/get_detail/${id}`);

export const apiAdminUserCreate = (body?: IParamsAminUserCreate) =>
  http.post<BaseAPIResponse<IAdminUser>>("/api/user/admin/create", body);

export const apiAdminUserUpdate = (id: string, body: IParamsAminUserCreate) =>
  http.patch<BaseAPIResponse<IAdminUser>>(`/api/user/admin/update/${id}`, body);

export const apiAdminUserDelete = (id: string) =>
  http.delete<BaseAPIResponse<unknown>>(`/api/user/admin/delete/${id}`);
