import {
  FileOutlined,
  UserOutlined,
  AppstoreOutlined,
  SolutionOutlined,
  ShopOutlined,
  ShoppingOutlined,
  FontColorsOutlined,
  BankOutlined,
  FileImageOutlined,
  KeyOutlined,
  BarChartOutlined,
  DollarCircleOutlined,
  InboxOutlined,
  UsergroupAddOutlined,
  NotificationOutlined,
  SafetyOutlined,
  ExclamationCircleOutlined,
  PercentageOutlined,
  GiftOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export const PATHS = {
  home: "/",
  listRole: "/list-role",
  listRoleDetail(id: string) {
    return `${this?.listRole}/${id}`;
  },
  listAdminUser: "/list-admin-user",
  listAdminUserDetail(id: string) {
    return `${this?.listAdminUser}/${id}`;
  },
  listUser: "/list-user",
  listUserDetail(id: string) {
    return `${this?.listUser}/${id}`;
  },
  listDebt: "/list-debt",
  listOrder: "/list-order",
  listOrderDetail(id: string) {
    return `${this?.listOrder}/${id}`;
  },
  listBranch: "/list-branch",
  listBranchDetail(id: string) {
    return `${this?.listBranch}/${id}`;
  },
  listCategoryProduct: "/list-category-product",
  listCategoryProductDetail(id: string) {
    return `${this?.listCategoryProduct}/${id}`;
  },
  listProduct: "/list-product",
  listProductDetail(id: string) {
    return `${this?.listProduct}/${id}`;
  },
  listGift: "/list-admin-gift",
  listGiftDetail(id: string) {
    return `${this?.listGift}/${id}`;
  },
  listGiftRequest: "/list-gift-request",
  listGiftRequestDetail(id: string) {
    return `${this?.listGiftRequest}/${id}`;
  },
  listDiscount: "/list-discount",
  listDiscountDetail(id: string) {
    return `${this?.listDiscount}/${id}`;
  },
  listAdminGuarantee: "/list-admin-guarantee",
  listAdminGuaranteeDetail(id: string) {
    return `${this?.listAdminGuarantee}/${id}`;
  },
  listAdminGuaranteeRequest: "/list-guarantee-request",
  listAdminGuaranteeRequestDetail(id: string) {
    return `${this?.listAdminGuaranteeRequest}/${id}`;
  },
  listNews: "/list-news",
  listNewsDetail(id: string) {
    return `${this?.listNews}/${id}`;
  },
  listBank: "/list-bank",
  listBankDetail(id: string) {
    return `${this?.listBank}/${id}`;
  },
  listKeywordSearch: "/list-keyword-search",
  login: "/login",
  listBanner: "/list-banner",
  listBannerDetail(id: string) {
    return `${this?.listBanner}/${id}`;
  },
  listKeyword: "/list-keyword",
  listKeywordDetail(id: string) {
    return `${this?.listKeyword}/${id}`;
  },
  listActivity: "/activity",
  listNotification: "/list-notification",
  listGuaranteeDetail: "/guarantee-detail",
  listUserRequest: "/list-user-request",
  listRepair: "/list-admin-repair",
  listRepairDetail(id: string) {
    return `${this?.listRepair}/${id}`;
  },
  listRepairRequest: "/list-repair-request",
  listRepairRequestDetail(id: string) {
    return `${this?.listRepairRequest}/${id}`;
  },
  listOrderPart: "/order-part",
  listOrderPartDetail(id: string) {
    return `${this?.listOrderPart}/${id}`;
  },
  listVehicle: "/list-vehicle",
  listVehicleDetail(id: string) {
    return `${this?.listVehicle}/${id}`;
  },
  listMusic: "/list-music",
  listMusicDetail(id: string) {
    return `${this?.listMusic}/${id}`;
  },
};

export const ROUTES = [
  {
    title: "Home",
    href: PATHS.home,
    icon: FileOutlined,
    page: "home/detail",
    hideMenu: true,
    role: "all",
  },
  {
    title: "Role",
    href: PATHS.listRole,
    icon: SolutionOutlined,
    page: "role/list",
    role: "role.get_list",
  },
  {
    title: "Role chi tiết",
    href: PATHS.listRoleDetail(":id"),
    icon: FileOutlined,
    page: "role/detail",
    hideMenu: true,
    role: "role.get_detail",
  },
  {
    title: "User Admin",
    href: PATHS.listAdminUser,
    icon: UsergroupAddOutlined,
    page: "adminUser/list",
    role: "admin.get_list",
  },
  {
    title: "User Admin chi tiết",
    href: PATHS.listAdminUserDetail(":id"),
    icon: FileOutlined,
    page: "adminUser/detail",
    hideMenu: true,
    role: "admin.get_detail",
  },
  {
    title: "User",
    href: PATHS.listUser,
    icon: UserOutlined,
    page: "user/list",
    role: "user.get_list",
  },
  {
    title: "User chi tiết",
    href: PATHS.listUserDetail(":id"),
    icon: FileOutlined,
    page: "user/detail",
    hideMenu: true,
    role: "user.get_detail",
  },
  // {
  //   title: "Công nợ",
  //   href: PATHS.listDebt,
  //   icon: DollarCircleOutlined,
  //   page: "debt/list",
  //   role: "user_debt.get_list",
  // },
  {
    title: "Đơn hàng",
    href: PATHS.listOrder,
    icon: InboxOutlined,
    page: "order/list",
    role: "order.get_list",
  },
  {
    title: "Đơn hàng chi tiết",
    href: PATHS.listOrderDetail(":id"),
    icon: FileOutlined,
    page: "order/detail",
    hideMenu: true,
    role: "order.get_detail",
  },
  {
    title: "Chi nhánh",
    href: PATHS.listBranch,
    icon: ShopOutlined,
    page: "branch/list",
    role: "branch.get_list",
  },
  {
    title: "Chi nhánh chi tiết",
    href: PATHS.listBranchDetail(":id"),
    icon: FileOutlined,
    page: "branch/detail",
    hideMenu: true,
    role: "branch.get_detail",
  },
  {
    title: "Danh mục sản phẩm",
    href: PATHS.listCategoryProduct,
    icon: AppstoreOutlined,
    page: "categoryProduct/list",
    role: "category.get_list",
  },
  {
    title: "Danh mục sản phẩm chi tiết",
    href: PATHS.listCategoryProductDetail(":id"),
    icon: FileOutlined,
    page: "categoryProduct/detail",
    hideMenu: true,
    role: "category.get_detail",
  },
  {
    title: "Sản phẩm",
    href: PATHS.listProduct,
    icon: ShoppingOutlined,
    page: "product/list",
    role: "product.get_list",
  },
  {
    title: "Sản phẩm chi tiết",
    href: PATHS.listProductDetail(":id"),
    icon: FileOutlined,
    page: "product/detail",
    hideMenu: true,
    role: "product.get_detail",
  },
  {
    title: "Order Part",
    href: PATHS.listOrderPart,
    icon: ShoppingOutlined,
    page: "orderPart/list",
    role: "order_part.get_list",
  },
  {
    title: "Chi tiết Order Part",
    href: PATHS.listOrderPartDetail(":id"),
    icon: FileOutlined,
    page: "orderPart/detail",
    hideMenu: true,
    role: "order_part.get_detail",
  },
  {
    title: "Dòng sản phẩm",
    href: PATHS.listVehicle,
    icon: ShoppingOutlined,
    page: "vehicleSeries/list",
    role: "vehicle_series.get_list",
  },
  {
    title: "Chi tiết dòng sản phẩm",
    href: PATHS.listVehicleDetail(":id"),
    icon: FileOutlined,
    page: "vehicleSeries/detail",
    hideMenu: true,
    role: "vehicle_series.get_detail",
  },
  // {
  //   title: "Quà tặng",
  //   href: PATHS.listGift,
  //   icon: GiftOutlined,
  //   page: "gift/list",
  //   role: "gift.get_list",
  // },
  // {
  //   title: "Quà tặng chi tiết",
  //   href: PATHS.listGiftDetail(":id"),
  //   icon: GiftOutlined,
  //   page: "gift/detail",
  //   hideMenu: true,
  //   role: "gift.get_detail",
  // },
  // {
  //   title: "Yêu cầu quà tặng",
  //   href: PATHS.listGiftRequest,
  //   icon: GiftOutlined,
  //   page: "giftRequest/list",
  //   role: "gift_request.get_list",
  // },
  // {
  //   title: "Yêu cầu quà tặng chi tiết",
  //   href: PATHS.listGiftRequestDetail(":id"),
  //   icon: GiftOutlined,
  //   page: "giftRequest/detail",
  //   hideMenu: true,
  //   role: "gift_request.get_detail",
  // },
  {
    title: "Giảm giá",
    href: PATHS.listDiscount,
    icon: PercentageOutlined,
    page: "discount/list",
    role: "discount.get_list",
  },
  {
    title: "Giảm giá chi tiết",
    href: PATHS.listDiscountDetail(":id"),
    icon: PercentageOutlined,
    page: "discount/detail",
    hideMenu: true,
    role: "discount.get_detail",
  },
  // {
  //   title: "Bảo hành",
  //   href: PATHS.listAdminGuarantee,
  //   icon: SafetyOutlined,
  //   page: "adminGuarantee/list",
  //   role: "guarantee.get_list",
  // },
  // {
  //   title: "Bảo hành chi tiết",
  //   href: PATHS.listAdminGuaranteeDetail(":id"),
  //   icon: SafetyOutlined,
  //   page: "adminGuarantee/detail",
  //   hideMenu: true,
  //   role: "guarantee.get_detail",
  // },
  // {
  //   title: "Yêu cầu bảo hành",
  //   href: PATHS.listAdminGuaranteeRequest,
  //   icon: SafetyCertificateOutlined,
  //   page: "adminGuaranteeRequest/list",
  //   role: "guarantee_request.get_list",
  // },
  // {
  //   title: "Yêu cầu bảo hành chi tiết",
  //   href: PATHS.listAdminGuaranteeRequestDetail(":id"),
  //   icon: SafetyCertificateOutlined,
  //   page: "adminGuaranteeRequest/detail",
  //   hideMenu: true,
  //   role: "guarantee_request.get_detail",
  // },
  // {
  //   title: "Sửa chữa",
  //   href: PATHS.listRepair,
  //   icon: SettingOutlined,
  //   page: "repair/list",
  //   role: "repair.get_list",
  // },
  // {
  //   title: "Sửa chữa chi tiết",
  //   href: PATHS.listRepairDetail(":id"),
  //   icon: SettingOutlined,
  //   page: "repair/detail",
  //   hideMenu: true,
  //   role: "repair.get_detail",
  // },
  // {
  //   title: "Yêu cầu sửa chữa",
  //   href: PATHS.listRepairRequest,
  //   icon: SettingOutlined,
  //   page: "repairRequest/list",
  //   role: "repair_request.get_list",
  // },
  // {
  //   title: "Yêu cầu sửa chữa chi tiết",
  //   href: PATHS.listRepairRequestDetail(":id"),
  //   icon: SettingOutlined,
  //   page: "repairRequest/detail",
  //   hideMenu: true,
  //   role: "repair_request.get_detail",
  // },
  {
    title: "Tin tức",
    href: PATHS.listNews,
    icon: FontColorsOutlined,
    page: "news/list",
    role: "news.get_list",
  },
  {
    title: "Tin tức chi tiết",
    href: PATHS.listNewsDetail(":id"),
    icon: FileOutlined,
    page: "news/detail",
    hideMenu: true,
    role: "news.get_detail",
  },
  {
    title: "Ngân hàng",
    href: PATHS.listBank,
    icon: BankOutlined,
    page: "bank/list",
    role: "bank_info.get_list",
  },
  {
    title: "Ngân hàng chi tiết",
    href: PATHS.listBankDetail(":id"),
    icon: FileOutlined,
    page: "bank/detail",
    hideMenu: true,
    role: "bank_info.get_detail",
  },
  {
    title: "Banner",
    href: PATHS.listBanner,
    icon: FileImageOutlined,
    page: "banner/list",
    role: "banner.get_list",
  },
  {
    title: "Banner chi tiết",
    href: PATHS.listBannerDetail(":id"),
    icon: FileOutlined,
    page: "banner/detail",
    hideMenu: true,
    role: "banner.get_detail",
  },
  {
    title: "Keyword",
    href: PATHS.listKeyword,
    icon: KeyOutlined,
    page: "keyword/list",
    role: "keyword.get_list",
  },
  {
    title: "Keyword chi tiết",
    href: PATHS.listKeywordDetail(":id"),
    icon: FileOutlined,
    page: "keyword/detail",
    hideMenu: true,
    role: "keyword.get_detail",
  },
  {
    title: "Hoạt động",
    href: PATHS.listActivity,
    icon: BarChartOutlined,
    page: "activity/list",
    role: "admin.get_list_log",
  },
  {
    title: "Quản lý nhạc",
    href: PATHS.listMusic,
    icon: BarChartOutlined,
    page: "music/list",
    role: "music.get_list",
  },
  {
    title: "Chi tiết âm nhạc",
    href: PATHS.listMusicDetail(":id"),
    icon: BarChartOutlined,
    page: "music/detail",
    hideMenu: true,
    role: "music.get_detail",
  },
  // {
  //   title: "Thông báo",
  //   href: PATHS.listNotification,
  //   icon: NotificationOutlined,
  //   page: "notification/list",
  //   role: "notification.get_list",
  // },
  // {
  //   title: "Bảo hành",
  //   href: PATHS.listGuaranteeDetail,
  //   icon: SafetyOutlined,
  //   page: "guarantee/detail",
  //   role: "guarantee.get_detail",
  // },
  {
    title: "Yêu cầu",
    href: PATHS.listUserRequest,
    icon: ExclamationCircleOutlined,
    page: "userRequest/list",
    role: "user_request.get_list",
  },
];
