import { CHECK_PERMISSION } from "constants/utils";
import { useAuth } from "providers/auth";
import { useMemo } from "react";

const useCheckPermission = (roles?: string | Array<string>) => {
  const {
    state: { user },
  } = useAuth();

  const listRoles = useMemo(
    () => user?.roles?.flatMap((x) => x.permission),
    [user?.roles]
  );

  return useMemo(
    () => ({
      fnCheck: (_roles?: string | Array<string>) =>
        CHECK_PERMISSION(_roles, listRoles),
      check: CHECK_PERMISSION(roles, listRoles),
    }),
    [listRoles, roles]
  );
};

export default useCheckPermission;
