// https://github.com/ckeditor/ckeditor5/issues/5884
import { CKEditor as CKEditorNative } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { apiUtilsUploadSingle } from "services/utils";
import "./index.css";
import { createRef } from "react";
type CKEditorProps = {
  value?: any;
  onChange?: (val: any) => void;
  readOnly?: boolean;
};

export const CKEditor: React.FC<CKEditorProps> = ({
  value,
  onChange,
  readOnly,
}) => {
  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file: any) => {
            body.append("files", file);
            apiUtilsUploadSingle(file)
              .then((res) => {
                resolve({
                  default: res.data.data,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return uploadAdapter(loader);
    };
  }
  const toolBarRef = createRef<HTMLDivElement>();

  return (
    <>
      <div ref={toolBarRef} />
      <CKEditorNative
        disabled={readOnly}
        config={{
          extraPlugins: [uploadPlugin],
          removePlugins: ["MediaEmbed", "FontFamily"],
        }}
        editor={DecoupledEditor}
        onReady={(editor: any) => {
          if (toolBarRef.current) {
            toolBarRef.current.appendChild(editor.ui.view.toolbar.element);
          }
        }}
        data={value || ""}
        onChange={(_: any, editor: any) => {
          const dataEditor = editor.getData();
          onChange && onChange(dataEditor);
        }}
      />
    </>
  );
};
