import React from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { authStorage } from "helpers/localStorage";
import { AuthAction, useAuth } from "providers/auth";
import { useAsync } from "hooks/useAsync";
import { PATHS } from "constants/routes";
import { apiAdminGetInfo } from "services/admin";

export const withPrivate =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const navigate = useNavigate();
    const token = authStorage.get("token");
    const {
      dispatch,
      state: { user },
    } = useAuth();

    useAsync(apiAdminGetInfo, {
      onSuccess: (res) => {
        dispatch({
          type: AuthAction.setAuth,
          payload: res.data.data,
        });
      },
      onFailed: () => {
        navigate(PATHS.login, { replace: true });
      },
      callOnFirst: !user && token,
    });

    if (!token && !user) {
      return <Navigate to={PATHS.login} replace />;
    }

    if (user) {
      return <WrappedComponent {...props} />;
    }

    return <div />;
  };
