import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext<{
  action?: React.ReactNode;
  setAction?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}>({
  action: undefined,
  setAction: undefined,
});

export const LayoutProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [action, setAction] = useState<React.ReactNode | undefined>(undefined);

  return (
    <>
      <LayoutContext.Provider value={{ action, setAction }}>
        {children}
      </LayoutContext.Provider>
    </>
  );
};

export const useLayout = () => useContext(LayoutContext);
