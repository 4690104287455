import { ErrorBoundary } from "components/errorBoundary";
import { AuthProvider } from "providers/auth";
import { LayoutProvider } from "providers/layout";
import { NotifyProvider } from "providers/notify";
import React from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const ProviderApp: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary>
      <NotifyProvider>
        <LayoutProvider>
        <DndProvider backend={HTML5Backend}>
            <AuthProvider>{children}</AuthProvider>
          </DndProvider>
        </LayoutProvider>
      </NotifyProvider>
    </ErrorBoundary>
  );
};
