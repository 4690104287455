function createStorage(key: string) {
  const store = JSON.parse(window.localStorage.getItem(key) || '{}');
  const save = () => {
    window.localStorage.setItem(key, JSON.stringify(store));
  };
  const storage = {
    get(key: string) {
      return store[key];
    },
    set(key: string, value: any) {
      store[key] = value;
      save();
    },
    remove(key: string) {
      delete store[key];
      save();
    },
  };
  return storage;
}

export const authStorage = createStorage('auth');
