import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const LIMIT = 10;
export const RANGE_TIME_SEARCH = "range_time";

const useSearch = () => {
  const [limit, setLimit] = useState(LIMIT);

  const [searchParams, setSearchParams] = useSearchParams();

  const setPage = useCallback(
    (_page: number) => {
      searchParams.set("page", _page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const search_type = useMemo(
    () => searchParams.get("search_type"),
    [searchParams]
  );

  const key_words = useMemo(
    () => searchParams.get("key_words"),
    [searchParams]
  );

  const setFilter = useCallback(
    ({
      order_by,
      sort,
      search_type,
      key_words,
    }: {
      order_by?: string;
      sort?: string;
      search_type?: string;
      key_words?: string;
    }) => {
      if (order_by && sort) {
        searchParams.set("order_by", order_by);
        searchParams.set("sort", sort);
      }

      if (search_type && key_words) {
        searchParams.set("search_type", search_type);
        if (search_type === RANGE_TIME_SEARCH) {
          searchParams.delete("key_words");
          searchParams.set(
            "start_date",
            (key_words as any)[0]["$d"].toString()
          );
          searchParams.set("end_date", (key_words as any)[1]["$d"].toString());
        } else {
          searchParams.delete("start_date");
          searchParams.delete("end_date");
          searchParams.set("key_words", key_words);
        }
      }

      if (!key_words) {
        searchParams.delete("key_words");
      }

      searchParams.set("page", "1");
      setLimit(LIMIT);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const resetFilter = useCallback(() => {
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    searchParams.delete("search_type");
    searchParams.delete("key_words");
    searchParams.delete("order_by");
    searchParams.delete("sort");
    searchParams.set("page", "1");
    setLimit(LIMIT);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const params = useMemo(() => {
    const page = Number(searchParams.get("page") || 1);
    const order_by = searchParams.get("order_by");
    const sort = searchParams.get("sort");

    const start_date = searchParams.get("start_date");
    const end_date = searchParams.get("end_date");
    let _params: any = {
      order_by,
      sort,
      limit,
      page,
      start_date: start_date ? new Date(start_date) : undefined,
      end_date: end_date ? new Date(end_date) : undefined,
    };
    if (search_type && key_words) {
      _params[search_type] = key_words;
    }
    return _params;
  }, [key_words, limit, searchParams, search_type]);

  return useMemo(
    () => ({
      setPage,
      setLimit,
      setFilter,
      resetFilter,
      params,
      search_type,
      key_words,
    }),
    [setPage, setFilter, resetFilter, params, search_type, key_words]
  );
};

export default useSearch;
