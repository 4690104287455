import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Row,
  Select,
  SelectProps,
  Space,
} from "antd";
import { Input } from "components";
import { Flex } from "components/flex";
import dayjs from "dayjs";
import useSearch, { RANGE_TIME_SEARCH } from "hooks/useSearch";
import React, { useEffect } from "react";

interface FilterProps {
  optionsOrderBy?: SelectProps["options"];
  optionsSearchType?: SelectProps["options"];
}

export const Filter: React.FC<FilterProps> = ({
  optionsOrderBy,
  optionsSearchType,
}) => {
  const [form] = Form.useForm();
  const { setFilter, resetFilter, params, search_type, key_words } =
    useSearch();

  useEffect(() => {
    form.setFieldsValue({
      sort: params.sort,
      order_by: params.orderBy,
      search_type: search_type,
      key_words:
        search_type === RANGE_TIME_SEARCH
          ? dayjs(params.start_date).isValid() &&
            dayjs(params.end_date).isValid()
            ? [params.start_date, params.end_date]
            : undefined
          : key_words,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Collapse>
        <Collapse.Panel header="Filter" key="1">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={setFilter}
            autoComplete="off"
          >
            <Row gutter={[16, 16]}>
              {optionsOrderBy && (
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={24} md={12}>
                      <Form.Item
                        label="Sắp xếp theo"
                        name="order_by"
                        style={{ marginBottom: 0 }}
                      >
                        <Select options={optionsOrderBy} />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={6}>
                      <Form.Item
                        label="Tăng hoặc giảm"
                        name="sort"
                        style={{ marginBottom: 0 }}
                      >
                        <Select
                          options={[
                            { label: "Giảm dần", value: "ASC" },
                            { label: "Tăng dần", value: "DESC" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {optionsSearchType && (
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={24} md={6}>
                      <Form.Item
                        label="Tìm kiếm theo"
                        name="search_type"
                        style={{ marginBottom: 0 }}
                      >
                        <Select
                          options={optionsSearchType}
                          onChange={() => {
                            form.resetFields(["key_words"]);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          const check =
                            prevValues.search_type !== curValues.search_type;
                          return check;
                        }}
                        style={{ marginBottom: 0 }}
                      >
                        {({ getFieldValue }) => {
                          const searchType = getFieldValue("search_type");
                          const find = optionsSearchType?.find(
                            (x) => x.value === searchType && x.optionsChild
                          );
                          if (searchType === RANGE_TIME_SEARCH) {
                            return (
                              <Form.Item
                                label="Nội dung tìm kiếm"
                                name="key_words"
                                getValueProps={(val) => {
                                  return {
                                    value: val
                                      ? val.map((x: any) => dayjs(x))
                                      : undefined,
                                  };
                                }}
                                style={{ marginBottom: 0 }}
                              >
                                <DatePicker.RangePicker />
                              </Form.Item>
                            );
                          }
                          if (find) {
                            return (
                              <Form.Item
                                label="Nội dung tìm kiếm"
                                name="key_words"
                                style={{ marginBottom: 0 }}
                              >
                                <Select options={find.optionsChild} />
                              </Form.Item>
                            );
                          }
                          return (
                            <Form.Item
                              label="Nội dung tìm kiếm"
                              name="key_words"
                              style={{ marginBottom: 0 }}
                            >
                              <Input />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Filter
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                      resetFilter();
                    }}
                  >
                    Reset
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <Flex height={10} />
    </>
  );
};
