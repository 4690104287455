import http from "../http";

import { IParamsNotificationCreate, INotification } from "./types";

export const apiNotificationGetList = (params?: IParamsPagination) =>
  http.get<BaseAPIResponse<Array<INotification>>>(
    "/api/notification/all/notification_detail",
    {
      params,
    }
  );

export const apiNotificationCreate = (params?: IParamsNotificationCreate) =>
  http.post<BaseAPIResponse<unknown>>("/api/notification/admin/create", params);
