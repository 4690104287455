export const FORMAT_CURRENCY = (amount?: number) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    minimumFractionDigits: 0,
  });
  return formatter.format(amount || 0).replaceAll(".", ",");
};

export const CALCULATE_PRICE_AFTER_DISCOUNT = (
  priceAfterDiscount?: number,
  discount?: number
) => {
  const _priceAfterDiscount = Number(priceAfterDiscount) || 0;
  const _discount = Number(discount) || 0;
  return Math.floor(_priceAfterDiscount / (1 - _discount / 100));
};

export const CHECK_PERMISSION = (
  roles?: string | Array<string>,
  listRoles?: Array<string>
) => {
  if (!roles || !listRoles) return false;
  if (typeof roles === "string") {
    return listRoles.includes(roles);
  }
  return roles.every(x => listRoles.includes(x));
};

export const generatePassword = () => {
  // Define arrays for each character set
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const specialCharacters = "@";
  const numbers = "0123456789";

  // Generate random characters
  const randomUppercase = uppercaseLetters.charAt(
    Math.floor(Math.random() * uppercaseLetters.length)
  );
  const randomLowercase = lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );
  const randomLowercase2 = lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );
  const randomLowercase3 = lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );
  const randomLowercase4 = lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );
  const randomNumber1 = numbers.charAt(
    Math.floor(Math.random() * numbers.length)
  );
  const randomNumber2 = numbers.charAt(
    Math.floor(Math.random() * numbers.length)
  );

  // Combine characters into password string
  const password =
    randomUppercase +
    randomLowercase +
    randomLowercase2 +
    randomLowercase3 +
    randomLowercase4 +
    specialCharacters +
    randomNumber1 +
    randomNumber2;

  // Return password string
  return password;
};
