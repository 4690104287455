import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { PATHS } from "constants/routes";
import { authStorage } from "helpers/localStorage";

declare module "axios" {
  export interface AxiosInstance {
    request<T = any, R = AxiosResponse<T>>(
      config: AxiosRequestConfig
    ): Promise<R>;
    get<T = any, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R>;
    delete<T = any, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R>;
    head<T = any, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R>;
    options<T = any, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R>;
    post<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<R>;
    put<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<R>;
    patch<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<R>;
  }
}

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": undefined,
    Accept: "application/json",
  },
});

http.interceptors.request.use(
  ($config) => {
    if ($config.headers) {
      const token = authStorage.get("token");
      if (token) {
        $config.headers.Authorization = `Bearer ${token}`;
      }
      $config.headers["Content-Type"] =
        $config.headers["Content-Type"] || "application/json";
      $config.headers.Accept = "application/json";
    }
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
);

http.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  async (error: AxiosError<any>) => {
    switch (error.response?.status) {
      case 401: {
        authStorage.remove("token");
        window.location.href = PATHS.home;
        break;
      }
      default:
        break;
    }

    return Promise.reject(error?.response?.data);
  }
);

export default http;
