import { Typography } from "antd";
import { Flex } from "components/flex";
import React from "react";

interface ErrorProps {
  error?: string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems="center"
      background={"#001529"}
      minHeight={"100vh"}
      padding={"50px 70px"}
    >
      <Typography.Title
        style={{
          color: "white",
          fontSize: "120px",
          lineHeight: "140px",
        }}
      >
        {error}
      </Typography.Title>
    </Flex>
  );
};
